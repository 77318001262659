import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import AboutMe from '../AboutMe';

export default function About() {
    return (
        <>
            <AboutMe />
        </>
    );
}
