import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';

export default function ContactPage() {
    return (
        <>
            <h1>Contact Me</h1>
            <Footer />

        </>
    );
}
